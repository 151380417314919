import React from "react";
import { Button, Card, CardContent, CardHeader, Container, Grid, Form, Message, Image } from "semantic-ui-react";
import { UsernameEmailField } from "../form-fields";
import { Link } from "gatsby";
import withTrans from "../../../i18n/withTrans";
import box from "../../../images/teaching/box.png";
import divbox from "../../../images/manage/divbox.png";
import btn1 from "../../../images/teaching/btn1.png";
import btn2 from "../../../images/teaching/btn2.png";
import btn3 from "../../../images/teaching/btn3.png";
import btn4 from "../../../images/teaching/btn4.png";
import { formatQuery } from "../../../controllers/utilities/string-utils";
import NavigationBar from "../navigation-bar";
import { navigate } from "gatsby";

function TeachingDetailView(props) {
    const {
        onContinue,
        back1
    } = props;
    const mode = detectOrient();
    return (
        <div className="whiteDiv" textAlign='center' style={{
            width: "100%",
            minHeight: "100vh",
            textAlign: "center",
            margin: "0 auto"
        }} >

<NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={2} onBack={onContinue}/>

            
            <div style={{ Width: "100%", height: "100vh", paddingTop: "80px", margin: "0 auto" }}>
                <div style={{ padding: "3vh" }}>

                    <Grid textAlign='center' style={{
                        margin: '0',
                        width: '100%',
                        minHeight:"60vh",
                        backgroundColor: "transparent",
                        backgroundImage: `url(${(mode==="PORTRAIT")?divbox:box})`, backgroundSize: '100% 100%',
                    }} verticalAlign='top'>

                        {
                            (mode === "PORTRAIT") && (
                                <Grid.Column style={{ maxWidth: 450, }}>
                                    <Image style={{width:"100%"
                        }} src={btn1} 
                        // as={Link}
                        // to={`/sdk-teaching1?back=${back1}`}
                        onClick={() => {
                            navigate(`/sdk-teaching1?back=${back1}`);
                        }}
                        />

                                    {/* <Button fluid size='large' style={{
                                        height: "4.5em",
                                        backgroundColor: "transparent",
                                        backgroundImage: `url(${btn1})`, backgroundSize: '100% 100%',
                                    }}
                                        as={Link}
                                        to={`/sdk-teaching1?back=${back1}`}>&nbsp;
                                    </Button> */}

<Image style={{width:"100%"
                        }} src={btn2} 
                        // as={Link}
                        // to={`/sdk-teaching2?back=${back1}`}
                        onClick={() => {
                            navigate(`/sdk-teaching2?back=${back1}`);
                        }}
                        />

<Image style={{width:"100%"
                        }} src={btn3} 
                        // as={Link}
                        // to={`/sdk-teaching2?back=${back1}`}
                        onClick={() => {
                            navigate(`/sdk-teaching3?back=${back1}&t3re=1`);
                        }}
                        />

<Image style={{width:"100%"
                        }} src={btn4} 
                        // as={Link}
                        // to={`/sdk-teaching2?back=${back1}`}
                        onClick={() => {
                            navigate(`/sdk-teaching4?back=${back1}`);
                        }}
                        />                        

                                    {/* <Button fluid size='large' style={{
                                        height: "4.5em",
                                        backgroundColor: "transparent",
                                        backgroundImage: `url(${btn2})`, backgroundSize: '100% 100%',
                                    }}
                                        as={Link}
                                        to={`/sdk-teaching2?back=${back1}`}>&nbsp;
                                    </Button> */}
                                </Grid.Column>)
                        }
                        {
                            (mode === "LANDSCAPE") && (
                                <Grid.Column style={{ width: "90%",padding:"3vh" }}>
                                    <Card fluid
                                        style={{
                                            float: "left",
                                            width: "50%",
                                            "margin": 0,
                                            backgroundColor: "transparent",
                                            boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                        }}>
                                            <Image style={{width:"100%"
                        }} src={btn1} 
                        // as={Link}
                        // to={`/sdk-teaching1?back=${back1}`}
                        onClick={() => {
                            navigate(`/sdk-teaching1?back=${back1}`);
                        }}
                        />

                                        {/* <Button fluid size='large' style={{
                                            height: "20vh",
                                            backgroundColor: "transparent",
                                            backgroundImage: `url(${btn1})`, backgroundSize: '100% 100%',
                                        }}
                                            as={Link}
                                            to={`/sdk-teaching1?back=${back1}`}>&nbsp;
                                        </Button> */}
                                        </Card>
                                    <Card fluid
                                        style={{
                                            float: "left",
                                            width: "50%",
                                            "margin": 0,
                                            backgroundColor: "transparent",
                                            boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                        }}>
                                            <Image style={{width:"100%"
                        }} src={btn2} 
                        // as={Link}
                        // to={`/sdk-teaching2?back=${back1}`}
                        onClick={() => {
                            navigate(`/sdk-teaching2?back=${back1}`);
                        }}
                        />

                                        {/* <Button fluid size='large' style={{
                                            height: "20vh",
                                            backgroundColor: "transparent",
                                            backgroundImage: `url(${btn2})`, backgroundSize: '100% 100%',
                                        }}
                                            as={Link}
                                            to={`/sdk-teaching2?back=${back1}`}>&nbsp;
                                        </Button> */}
                                    </Card>

                                    <Card fluid
                                        style={{
                                            float: "left",
                                            width: "50%",
                                            "margin": 0,
                                            backgroundColor: "transparent",
                                            boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                        }}>
                                            <Image style={{width:"100%"
                        }} src={btn3} 
                        onClick={() => {
                            navigate(`/sdk-teaching3?back=${back1}&t3re=1`);
                        }}
                        />

                                        
                                    </Card>

                                    <Card fluid
                                        style={{
                                            float: "left",
                                            width: "50%",
                                            "margin": 0,
                                            backgroundColor: "transparent",
                                            boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                        }}>
                                            <Image style={{width:"100%"
                        }} src={btn4} 
                        onClick={() => {
                            navigate(`/sdk-teaching4?back=${back1}`);
                        }}
                        />

                                        
                                    </Card>
                                </Grid.Column>
                            )}
                    </Grid>
                </div></div>
        </div>
    );
}

export default withTrans(TeachingDetailView);

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}